import React from 'react';
import {Anchor, Grommet, Heading, Main, Paragraph} from "grommet";

function App() {

    const theme = {
        global: {
            font: {
                family: 'Muli',
                size: '14px',
                height: '20px',
            }
        },
        paragraph: {
            medium: {
                maxWidth: "600px",
            }
        }
    };

    return (
        <Grommet theme={theme}>
            <Main margin="large">
                <Heading>@arnold-kb.de</Heading>
                <Paragraph>
                    Welcome to our family server.
                    The primary purpose of this server is to handle our family's electronic mail.
                    If you've got questions regarding the mail server visit <Anchor href="https://delivery.arnold-kb.de">
                    delivery.arnold-kb.de</Anchor>.
                </Paragraph>
                <Paragraph>

                </Paragraph>
            </Main>
        </Grommet>
    );
}

export default App;
